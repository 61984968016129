import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from "gatsby";

import { Gallery, GalleryPhoto } from '../components/gallery';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { ReferenceImageModel } from '../servermodels/referenceImageModel';



const Reference: React.FC = () => {
    const [ showGallery, setShowGallery ]= useState(false);
    const [ imageIndex, setImageIndex ] = useState(0);

    function handleHideGallery() {
        setShowGallery(false);
    }

    function handleShowGallery(index: number) {
        setImageIndex(index);
        setShowGallery(true);
    }

    const images: ReferenceImageModel[] = useStaticQuery(graphql`
    query ReferenceImages {
        allStrapiReferenceImage {
            nodes {
                id,
                Title,
                Photo {
                    alternativeText,
                    caption,
                    localFile {
                        publicURL
                    }
                }
            }
        }
    }
    `).allStrapiReferenceImage.nodes;

    const data: GalleryPhoto[] = images.map(i => ({
        original: i.Photo.localFile.publicURL,
        thumbnail: i.Photo.localFile.publicURL,
        description: i.Photo.caption,
        originalTitle: i.Photo.caption,
    }));

    return (
        <Layout contentIsPage currentPageTitle="Reference">
            <SEO title="Reference" />
            <Gallery show={showGallery} photos={data} onClose={handleHideGallery} startIndex={imageIndex} />
            <div className="container page page-reference">
                <h1 className="main-title page">
                    <span>Reference</span>
                </h1>
                <div className="col">
                    <div className="page-reference--gal">
                        {images.map((r, i) =>
                            <div key={r.id} className="page-reference--gal-box" onClick={() => handleShowGallery(i)} >
                                <div className="page-reference--gal-box-wrapper">
                                    <img src={r.Photo.localFile.publicURL} title={r.Title} alt={r.Photo.alternativeText} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Reference;